import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Backoffice from '../../core/sysext/CMS/containers/Backoffice';
import PostForm from '../../core/sysext/Website/containers/Post/components/Form';
import PostList from '../../core/sysext/Website/containers/Post/components/List';

const PostsPage: FC<PageProps> = props => {
  const {
    location,
    params: { '*': star },
  } = props;

  return (
    <Backoffice location={location}>
      {star ? <PostForm docId={star} {...props} /> : <PostList {...props} />}
    </Backoffice>
  );
};

export default PostsPage;
