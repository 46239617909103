import { PostData } from '@innedit/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import requireBoutique, {
  BoutiqueProps,
} from '../../../../../../utils/requireBoutique';
import CMSView from '../../../../../CMS/components/View/index';
import List from '../../../../../CMS/containers/Boutique/components/new/List';
import params from '../../params.json';
import Item from './Item';

const PostList: FC<BoutiqueProps> = ({ boutique }) => {
  const { t } = useTranslation();

  return (
    <CMSView>
      <List
        item={Item}
        itemPathnamePrefix="/website/articles/"
        listClassName=""
        model={new PostData({ boutique, params })}
        title={t('posts')}
      />
    </CMSView>
  );
};

export default requireBoutique(PostList);
